.widget {
  display: inline-block;
  width: 62%;
  margin: 0 auto;
  overflow: visible;
  text-align: center; }
  .widget .widget-body {
    padding: 8px 0;
    background: #f8f8f8;
    position: relative; }
