.chart-day-container {
  position: relative;
  z-index: 5;
  color: #3a3a3a;
  width: 100%;
  padding: 8px;
  line-height: 100%;
  text-align: left;
  transition: all 400ms cubic-bezier(0.4, 0, 0.2, 1); }
  .chart-day-container .chart-day-name {
    display: block;
    font-size: 20px;
    font-weight: bolder;
    line-height: 110%; }
  .chart-day-container .chart-day-percent {
    display: block;
    font-size: 16px;
    font-weight: lighter; }
  .chart-day-container .chart-day-num {
    display: inline-block;
    font-weight: bolder;
    font-size: 46px;
    line-height: 100%;
    margin-right: 8px; }
  .chart-day-container .dayinfo-container {
    display: inline-block; }
