.calendar-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  border: 1px solid #e4e4e4;
  position: relative;
  top: -8px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.06), 0 2px 6px rgba(0, 0, 0, 0.03);
  background: #fdfdfd; }
  .calendar-container .calendar-col {
    display: inline-block;
    flex-grow: 1;
    text-align: left;
    border-right: 1px solid #e4e4e4;
    width: 14.2%; }
  .calendar-container .calendar-col:last-child {
    border-right: none; }

.calendar-container.in-nav {
  border: none;
  top: 0;
  box-shadow: none;
  background: none;
  display: block; }
  .calendar-container.in-nav .calendar-col {
    border: none; }
  .calendar-container.in-nav .calendar-nav {
    width: 100%;
    display: block;
    font-size: 12px;
    font-weight: bold; }
    .calendar-container.in-nav .calendar-nav .calendar-nav-month {
      padding: 3px;
      font-size: 13px;
      display: inline-block; }
    .calendar-container.in-nav .calendar-nav .calendar-nav-arrow {
      padding: 3px;
      display: inline-block; }
    .calendar-container.in-nav .calendar-nav .calendar-nav-arrow:hover {
      background: #e4e4e4; }
