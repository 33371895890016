main#dashboard-container {
  margin: 0;
  margin-top: 1px;
  text-align: center;
  position: relative;
  width: 100%;
  height: calc(100% - 50px);
  overflow-x: hidden; }

.footer {
  position: relative;
  bottom: 0; }
