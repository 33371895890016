/* Widget controls */
.widget-controls-container .widget-date {
  font-weight: bold; }

.widget-controls-container .widget-carot::after {
  display: inline-block;
  position: relative;
  top: 0px;
  left: 10px;
  width: 0;
  cursor: pointer;
  content: "";
  border: 4px solid;
  border-radius: 2px;
  border-color: #3a3a3a transparent transparent transparent; }

.widget-controls-container .widget-options {
  display: none;
  position: absolute;
  background: #fdfdfd;
  top: 100%;
  left: 0;
  margin: 0;
  list-style: none;
  z-index: 10;
  font-weight: lighter;
  width: 100%;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.06), 0 2px 6px rgba(0, 0, 0, 0.03); }
  .widget-controls-container .widget-options .widget-item {
    padding: 8px 16px;
    cursor: pointer; }
  .widget-controls-container .widget-options .widget-item:nth-child(even) {
    background: #f8f8f8; }
  .widget-controls-container .widget-options .widget-item:hover {
    background: #e4e4e4; }
  .widget-controls-container .widget-options .widget-item.current {
    font-weight: bold; }
  .widget-controls-container .widget-options .widget-item.calendar {
    padding: 3px; }
  .widget-controls-container .widget-options .widget-item.calendar:hover {
    background: #f8f8f8 !important; }

.widget-controls-container .widget-options.expanded {
  display: block; }

.widget-controls-container .widget-carot.expanded::after {
  transform: rotate(180deg);
  top: -3px; }

.widget-controls-container .widget-controls:hover .widget-title {
  text-decoration: none; }

.drop-down-container {
  position: relative;
  display: inline-block;
  background: #e4e4e4;
  width: 160px;
  cursor: pointer; }
  .drop-down-container .drop-down-button {
    padding: 8px 16px;
    padding-right: 20px; }
