@import url("https://fonts.googleapis.com/css?family=IBM+Plex+Sans:300,400,700");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "IBM Plex Sans", sans-serif; }

html,
body,
#root,
.container {
  background: #f8f8f8;
  overflow: hidden;
  position: relative;
  height: 100%;
  color: #3a3a3a; }

.bold {
  font-weight: bold; }
