.horizontal-chart {
  position: absolute;
  left: 0;
  top: 0;
  height: 24px;
  width: 100%;
  background: #fdfdfd;
  margin: 0;
  overflow: hidden;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.06), 0 2px 6px rgba(0, 0, 0, 0.03);
  border-radius: 3px;
  border: 1px solid #e4e4e4;
  transition: box-shadow 400ms cubic-bezier(0.4, 0, 0.2, 1); }
  .horizontal-chart .chart-bar {
    height: 100%;
    position: absolute;
    width: 100%; }
  .horizontal-chart .chart-bar.extend {
    background: #3a3a3a;
    z-index: 2;
    transition: width 400ms cubic-bezier(0.4, 0, 0.2, 1); }

.horizontal-chart.full {
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.13), 0 2px 6px rgba(0, 0, 0, 0.1); }

.vertical-chart {
  cursor: pointer;
  display: inline-block;
  height: 500px;
  width: 100%;
  background: #f8f8f8;
  margin: 0;
  overflow: hidden;
  box-shadow: none;
  transition: box-shadow 400ms cubic-bezier(0.4, 0, 0.2, 1); }
  .vertical-chart .chart-bar {
    height: 100%;
    position: absolute;
    width: 100%;
    bottom: 0; }
  .vertical-chart .chart-bar.extend {
    background: #3a3a3a;
    z-index: 2;
    transition: height 400ms cubic-bezier(0.4, 0, 0.2, 1); }
  .vertical-chart .chart-container {
    width: 100%;
    height: 100%;
    position: relative; }
