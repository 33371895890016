.flex-container {
  display: flex; }
  .flex-container .flex-right,
  .flex-container .flex-left {
    width: 50%;
    display: block;
    position: relative; }
  .flex-container .flex-left {
    width: auto; }
  .flex-container .flex-right {
    flex-grow: 1;
    text-align: center; }

nav,
.brand,
nav ul {
  display: inline-block; }

header {
  height: 50px; }
  header .brand {
    position: relative;
    margin-left: 8px;
    padding: 6px;
    font-size: 24px;
    font-weight: bold;
    font-style: italic;
    letter-spacing: -2px;
    cursor: pointer;
    z-index: 10;
    border-bottom: none; }
  header nav {
    position: absolute;
    left: 0;
    width: 100%;
    text-align: center;
    z-index: 5; }
    header nav .nav-item {
      display: inline-block;
      margin: 0 16px;
      cursor: pointer;
      font-weight: light; }
      header nav .nav-item a {
        border-bottom: none;
        padding: 14px 32px;
        display: block; }
    header nav .nav-item.current {
      font-weight: bold !important; }
