.goal {
  background: #fdfdfd;
  position: relative;
  height: 135px;
  width: calc(25% - 24px);
  min-width: 175px;
  margin: 0 16px;
  margin-bottom: 32px;
  padding: 10px;
  color: #3a3a3a;
  text-align: center;
  font-size: 18px;
  cursor: pointer;
  border-radius: 3px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.06), 0 2px 6px rgba(0, 0, 0, 0.03);
  transition: box-shadow 100ms cubic-bezier(0.4, 0, 0.2, 1), color 100ms cubic-bezier(0.4, 0, 0.2, 1), background 100ms cubic-bezier(0.4, 0, 0.2, 1), border 100ms cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 3; }
  .goal .goal-body {
    display: inline-block;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    z-index: 5; }
    .goal .goal-body .goal-input {
      border: none;
      font-size: inherit;
      display: inline;
      min-width: 80px !important; }
  .goal .goal-body:after {
    content: '';
    position: relative;
    left: 50%;
    top: 3px;
    display: block;
    background: #3a3a3a;
    width: 0%;
    height: 1.5px;
    opacity: 0;
    transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1); }
  .goal .bg-link {
    position: absolute;
    content: '';
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 4;
    border-bottom: none; }
  .goal .goal-body:hover:after,
  .goal .goal-body.editing:after {
    width: 100%;
    left: 0%;
    top: 0;
    opacity: 1; }

.goal:nth-child(4n + 1) {
  margin-left: 0; }

.goal:nth-child(4n) {
  margin-right: 0; }

.goal.done {
  background: #3a3a3a;
  color: #fdfdfd; }

.goal:hover {
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.13), 0 2px 6px rgba(0, 0, 0, 0.1); }

.goal.button {
  background: none;
  color: #e4e4e4;
  font-size: 20px;
  box-shadow: none;
  border: 4px dashed #e4e4e4;
  font-weight: normal;
  padding: 0; }
  .goal.button .goal-plus {
    font-size: 112px;
    line-height: 100%;
    font-weight: lighter; }

.goal.button:hover {
  border-color: #3a3a3a;
  color: #3a3a3a; }
