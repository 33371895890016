.calendar-day {
  position: relative;
  height: 8vw;
  border-bottom: 1px solid #e4e4e4;
  padding: 8px 8px;
  cursor: pointer;
  box-shadow: none;
  overflow: hidden;
  transition: all 100ms cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 2;
  width: 100%; }
  .calendar-day .calendar-date {
    font-size: 20px;
    line-height: 100%;
    font-weight: lighter; }
  .calendar-day .calendar-perc {
    display: none;
    position: absolute;
    padding-top: 6px;
    padding-bottom: 8px;
    bottom: 0;
    left: 0;
    font-size: 12px;
    line-height: 100%;
    width: 100%;
    text-align: center;
    background: #fdfdfd; }
  .calendar-day .goal-container {
    padding-top: 6px;
    width: 100%; }
    .calendar-day .goal-container .mini-goal {
      display: inline-block;
      font-size: 11px;
      padding: 2px 4px;
      margin-top: -3px;
      margin-bottom: 2px;
      margin-right: 2px;
      background: #3a3a3a;
      color: #fdfdfd;
      border-radius: 2px;
      border: 1px solid #3a3a3a;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden; }
    .calendar-day .goal-container .mini-goal.done {
      background: #3a3a3a;
      color: #fdfdfd; }
    .calendar-day .goal-container .mini-goal.undone {
      background: #fdfdfd;
      color: #3a3a3a; }

.calendar-day:last-child {
  border-bottom: none; }

.calendar-day:hover {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.06), 0 2px 6px rgba(0, 0, 0, 0.03);
  z-index: 3; }

.calendar-day.previous > * {
  opacity: 0.3; }

.calendar-day.today {
  background: #e4e4e4; }
  .calendar-day.today .calendar-perc {
    background: #e4e4e4; }

.calendar-day.in-nav {
  height: auto;
  padding: 0;
  border: none;
  text-align: center; }
  .calendar-day.in-nav .calendar-date {
    display: inline-block;
    font-size: 12px;
    border-radius: 0%;
    width: 100%;
    padding: 5px 0; }

.calendar-day.in-nav:hover {
  box-shadow: none; }
  .calendar-day.in-nav:hover .calendar-date {
    background: #e4e4e4; }

.calendar-day.in-nav.current {
  background: #3a3a3a !important;
  color: #fdfdfd; }
  .calendar-day.in-nav.current .calendar-date {
    background: none; }
