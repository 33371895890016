.widget .widget-controls {
  display: block;
  text-align: center;
  padding: 32px 0; }
  .widget .widget-controls .widget-title {
    font-weight: lighter;
    padding: 8px 16px;
    padding-top: 0;
    margin-bottom: 8px;
    display: block;
    text-align: center;
    font-size: 56px;
    line-height: 100%; }
  .widget .widget-controls .widget-previous,
  .widget .widget-controls .widget-next {
    display: inline-block;
    position: relative;
    font-weight: bolder;
    line-height: 100%;
    cursor: pointer;
    z-index: 10;
    padding: 8px 16px;
    transform: scaleX(1);
    transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1); }
  .widget .widget-controls .widget-previous {
    margin-right: 8px;
    transform-origin: right; }
  .widget .widget-controls .widget-next {
    margin-left: 8px;
    transform-origin: left; }
  .widget .widget-controls .widget-previous::selection,
  .widget .widget-controls .widget-next::selection {
    background: none; }
  .widget .widget-controls .widget-previous:hover,
  .widget .widget-controls .widget-next:hover {
    transform: scaleX(1.2); }
