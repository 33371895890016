@import url(https://fonts.googleapis.com/css?family=IBM+Plex+Sans:300,400,700);
a {
  text-decoration: none;
  border-bottom: 0.5px solid #3a3a3a;
  color: inherit; }

.flex-container {
  display: flex; }
  .flex-container .flex-right,
  .flex-container .flex-left {
    width: 50%;
    display: block;
    position: relative; }
  .flex-container .flex-left {
    width: auto; }
  .flex-container .flex-right {
    flex-grow: 1;
    text-align: center; }

nav,
.brand,
nav ul {
  display: inline-block; }

header {
  height: 50px; }
  header .brand {
    position: relative;
    margin-left: 8px;
    padding: 6px;
    font-size: 24px;
    font-weight: bold;
    font-style: italic;
    letter-spacing: -2px;
    cursor: pointer;
    z-index: 10;
    border-bottom: none; }
  header nav {
    position: absolute;
    left: 0;
    width: 100%;
    text-align: center;
    z-index: 5; }
    header nav .nav-item {
      display: inline-block;
      margin: 0 16px;
      cursor: pointer;
      font-weight: light; }
      header nav .nav-item a {
        border-bottom: none;
        padding: 14px 32px;
        display: block; }
    header nav .nav-item.current {
      font-weight: bold !important; }

/* Widget controls */
.widget-controls-container .widget-date {
  font-weight: bold; }

.widget-controls-container .widget-carot::after {
  display: inline-block;
  position: relative;
  top: 0px;
  left: 10px;
  width: 0;
  cursor: pointer;
  content: "";
  border: 4px solid;
  border-radius: 2px;
  border-color: #3a3a3a transparent transparent transparent; }

.widget-controls-container .widget-options {
  display: none;
  position: absolute;
  background: #fdfdfd;
  top: 100%;
  left: 0;
  margin: 0;
  list-style: none;
  z-index: 10;
  font-weight: lighter;
  width: 100%;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.06), 0 2px 6px rgba(0, 0, 0, 0.03); }
  .widget-controls-container .widget-options .widget-item {
    padding: 8px 16px;
    cursor: pointer; }
  .widget-controls-container .widget-options .widget-item:nth-child(even) {
    background: #f8f8f8; }
  .widget-controls-container .widget-options .widget-item:hover {
    background: #e4e4e4; }
  .widget-controls-container .widget-options .widget-item.current {
    font-weight: bold; }
  .widget-controls-container .widget-options .widget-item.calendar {
    padding: 3px; }
  .widget-controls-container .widget-options .widget-item.calendar:hover {
    background: #f8f8f8 !important; }

.widget-controls-container .widget-options.expanded {
  display: block; }

.widget-controls-container .widget-carot.expanded::after {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  top: -3px; }

.widget-controls-container .widget-controls:hover .widget-title {
  text-decoration: none; }

.drop-down-container {
  position: relative;
  display: inline-block;
  background: #e4e4e4;
  width: 160px;
  cursor: pointer; }
  .drop-down-container .drop-down-button {
    padding: 8px 16px;
    padding-right: 20px; }

.calendar-day {
  position: relative;
  height: 8vw;
  border-bottom: 1px solid #e4e4e4;
  padding: 8px 8px;
  cursor: pointer;
  box-shadow: none;
  overflow: hidden;
  transition: all 100ms cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 2;
  width: 100%; }
  .calendar-day .calendar-date {
    font-size: 20px;
    line-height: 100%;
    font-weight: lighter; }
  .calendar-day .calendar-perc {
    display: none;
    position: absolute;
    padding-top: 6px;
    padding-bottom: 8px;
    bottom: 0;
    left: 0;
    font-size: 12px;
    line-height: 100%;
    width: 100%;
    text-align: center;
    background: #fdfdfd; }
  .calendar-day .goal-container {
    padding-top: 6px;
    width: 100%; }
    .calendar-day .goal-container .mini-goal {
      display: inline-block;
      font-size: 11px;
      padding: 2px 4px;
      margin-top: -3px;
      margin-bottom: 2px;
      margin-right: 2px;
      background: #3a3a3a;
      color: #fdfdfd;
      border-radius: 2px;
      border: 1px solid #3a3a3a;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden; }
    .calendar-day .goal-container .mini-goal.done {
      background: #3a3a3a;
      color: #fdfdfd; }
    .calendar-day .goal-container .mini-goal.undone {
      background: #fdfdfd;
      color: #3a3a3a; }

.calendar-day:last-child {
  border-bottom: none; }

.calendar-day:hover {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.06), 0 2px 6px rgba(0, 0, 0, 0.03);
  z-index: 3; }

.calendar-day.previous > * {
  opacity: 0.3; }

.calendar-day.today {
  background: #e4e4e4; }
  .calendar-day.today .calendar-perc {
    background: #e4e4e4; }

.calendar-day.in-nav {
  height: auto;
  padding: 0;
  border: none;
  text-align: center; }
  .calendar-day.in-nav .calendar-date {
    display: inline-block;
    font-size: 12px;
    border-radius: 0%;
    width: 100%;
    padding: 5px 0; }

.calendar-day.in-nav:hover {
  box-shadow: none; }
  .calendar-day.in-nav:hover .calendar-date {
    background: #e4e4e4; }

.calendar-day.in-nav.current {
  background: #3a3a3a !important;
  color: #fdfdfd; }
  .calendar-day.in-nav.current .calendar-date {
    background: none; }

.calendar-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  border: 1px solid #e4e4e4;
  position: relative;
  top: -8px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.06), 0 2px 6px rgba(0, 0, 0, 0.03);
  background: #fdfdfd; }
  .calendar-container .calendar-col {
    display: inline-block;
    flex-grow: 1;
    text-align: left;
    border-right: 1px solid #e4e4e4;
    width: 14.2%; }
  .calendar-container .calendar-col:last-child {
    border-right: none; }

.calendar-container.in-nav {
  border: none;
  top: 0;
  box-shadow: none;
  background: none;
  display: block; }
  .calendar-container.in-nav .calendar-col {
    border: none; }
  .calendar-container.in-nav .calendar-nav {
    width: 100%;
    display: block;
    font-size: 12px;
    font-weight: bold; }
    .calendar-container.in-nav .calendar-nav .calendar-nav-month {
      padding: 3px;
      font-size: 13px;
      display: inline-block; }
    .calendar-container.in-nav .calendar-nav .calendar-nav-arrow {
      padding: 3px;
      display: inline-block; }
    .calendar-container.in-nav .calendar-nav .calendar-nav-arrow:hover {
      background: #e4e4e4; }

.widget .widget-controls {
  display: block;
  text-align: center;
  padding: 32px 0; }
  .widget .widget-controls .widget-title {
    font-weight: lighter;
    padding: 8px 16px;
    padding-top: 0;
    margin-bottom: 8px;
    display: block;
    text-align: center;
    font-size: 56px;
    line-height: 100%; }
  .widget .widget-controls .widget-previous,
  .widget .widget-controls .widget-next {
    display: inline-block;
    position: relative;
    font-weight: bolder;
    line-height: 100%;
    cursor: pointer;
    z-index: 10;
    padding: 8px 16px;
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1); }
  .widget .widget-controls .widget-previous {
    margin-right: 8px;
    -webkit-transform-origin: right;
            transform-origin: right; }
  .widget .widget-controls .widget-next {
    margin-left: 8px;
    -webkit-transform-origin: left;
            transform-origin: left; }
  .widget .widget-controls .widget-previous::-moz-selection,
  .widget .widget-controls .widget-next::-moz-selection {
    background: none; }
  .widget .widget-controls .widget-previous::selection,
  .widget .widget-controls .widget-next::selection {
    background: none; }
  .widget .widget-controls .widget-previous:hover,
  .widget .widget-controls .widget-next:hover {
    -webkit-transform: scaleX(1.2);
            transform: scaleX(1.2); }

.chart-day-container {
  position: relative;
  z-index: 5;
  color: #3a3a3a;
  width: 100%;
  padding: 8px;
  line-height: 100%;
  text-align: left;
  transition: all 400ms cubic-bezier(0.4, 0, 0.2, 1); }
  .chart-day-container .chart-day-name {
    display: block;
    font-size: 20px;
    font-weight: bolder;
    line-height: 110%; }
  .chart-day-container .chart-day-percent {
    display: block;
    font-size: 16px;
    font-weight: lighter; }
  .chart-day-container .chart-day-num {
    display: inline-block;
    font-weight: bolder;
    font-size: 46px;
    line-height: 100%;
    margin-right: 8px; }
  .chart-day-container .dayinfo-container {
    display: inline-block; }

.horizontal-chart {
  position: absolute;
  left: 0;
  top: 0;
  height: 24px;
  width: 100%;
  background: #fdfdfd;
  margin: 0;
  overflow: hidden;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.06), 0 2px 6px rgba(0, 0, 0, 0.03);
  border-radius: 3px;
  border: 1px solid #e4e4e4;
  transition: box-shadow 400ms cubic-bezier(0.4, 0, 0.2, 1); }
  .horizontal-chart .chart-bar {
    height: 100%;
    position: absolute;
    width: 100%; }
  .horizontal-chart .chart-bar.extend {
    background: #3a3a3a;
    z-index: 2;
    transition: width 400ms cubic-bezier(0.4, 0, 0.2, 1); }

.horizontal-chart.full {
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.13), 0 2px 6px rgba(0, 0, 0, 0.1); }

.vertical-chart {
  cursor: pointer;
  display: inline-block;
  height: 500px;
  width: 100%;
  background: #f8f8f8;
  margin: 0;
  overflow: hidden;
  box-shadow: none;
  transition: box-shadow 400ms cubic-bezier(0.4, 0, 0.2, 1); }
  .vertical-chart .chart-bar {
    height: 100%;
    position: absolute;
    width: 100%;
    bottom: 0; }
  .vertical-chart .chart-bar.extend {
    background: #3a3a3a;
    z-index: 2;
    transition: height 400ms cubic-bezier(0.4, 0, 0.2, 1); }
  .vertical-chart .chart-container {
    width: 100%;
    height: 100%;
    position: relative; }

.goal {
  background: #fdfdfd;
  position: relative;
  height: 135px;
  width: calc(25% - 24px);
  min-width: 175px;
  margin: 0 16px;
  margin-bottom: 32px;
  padding: 10px;
  color: #3a3a3a;
  text-align: center;
  font-size: 18px;
  cursor: pointer;
  border-radius: 3px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.06), 0 2px 6px rgba(0, 0, 0, 0.03);
  transition: box-shadow 100ms cubic-bezier(0.4, 0, 0.2, 1), color 100ms cubic-bezier(0.4, 0, 0.2, 1), background 100ms cubic-bezier(0.4, 0, 0.2, 1), border 100ms cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 3; }
  .goal .goal-body {
    display: inline-block;
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    z-index: 5; }
    .goal .goal-body .goal-input {
      border: none;
      font-size: inherit;
      display: inline;
      min-width: 80px !important; }
  .goal .goal-body:after {
    content: '';
    position: relative;
    left: 50%;
    top: 3px;
    display: block;
    background: #3a3a3a;
    width: 0%;
    height: 1.5px;
    opacity: 0;
    transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1); }
  .goal .bg-link {
    position: absolute;
    content: '';
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 4;
    border-bottom: none; }
  .goal .goal-body:hover:after,
  .goal .goal-body.editing:after {
    width: 100%;
    left: 0%;
    top: 0;
    opacity: 1; }

.goal:nth-child(4n + 1) {
  margin-left: 0; }

.goal:nth-child(4n) {
  margin-right: 0; }

.goal.done {
  background: #3a3a3a;
  color: #fdfdfd; }

.goal:hover {
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.13), 0 2px 6px rgba(0, 0, 0, 0.1); }

.goal.button {
  background: none;
  color: #e4e4e4;
  font-size: 20px;
  box-shadow: none;
  border: 4px dashed #e4e4e4;
  font-weight: normal;
  padding: 0; }
  .goal.button .goal-plus {
    font-size: 112px;
    line-height: 100%;
    font-weight: lighter; }

.goal.button:hover {
  border-color: #3a3a3a;
  color: #3a3a3a; }

.goal-field {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 64px; }

.widget {
  display: inline-block;
  width: 62%;
  margin: 0 auto;
  overflow: visible;
  text-align: center; }
  .widget .widget-body {
    padding: 8px 0;
    background: #f8f8f8;
    position: relative; }

.widget .widget-body.week {
  padding: 0;
  display: flex;
  flex-flow: nowrap; }

main#dashboard-container {
  margin: 0;
  margin-top: 1px;
  text-align: center;
  position: relative;
  width: 100%;
  height: calc(100% - 50px);
  overflow-x: hidden; }

.footer {
  position: relative;
  bottom: 0; }

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "IBM Plex Sans", sans-serif; }

html,
body,
#root,
.container {
  background: #f8f8f8;
  overflow: hidden;
  position: relative;
  height: 100%;
  color: #3a3a3a; }

.bold {
  font-weight: bold; }

